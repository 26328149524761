/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { Link } from "gatsby";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";
import banner from "../../static/imgs/heartfulness-green-donation-banner.jpg";

const RecurringGeneralDonationHI = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "General Donation Recurring HI, USA";
    return `<gatsby_donation donationId = "${id}" btntext = "${titletext}" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "General Donation Recurring - HI, USA" />`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) =>
      eventCallbackTriggers("General_Donation_Recurring_-_HI,_USA", event),
    []
  );

  return (
    <>
      <DonationLayout
        seoData={{
          title: "Heartfulness Donations",
        }}
      >
        <Box
          sx={{
            paddingTop: "30px",
            alignItems: "center",
            minHeight: "620px",
            maxHeight: "620px",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${banner})`,
          }}
        >
          <Box sx={{ paddingBottom: "30px" }}>
            <h2
              sx={{
                color: "White",
                fontWeight: "500",
                lineHeight: "1.2",
                fontSize: "55px",
                textAlign: "center!important",
              }}
            >
              Heartfulness USA Donations – Recurring
            </h2>
            <PageContent
              eventCallback={eventCallback}
              pageContent={pageContent(25, "Donate Now")}
            />
          </Box>
        </Box>
        <Grid style={{ marginBottom: "15px", padding: "20px 20px" }}>
          <Box>
            <h1
              sx={{
                marginTop: "15px",
                fontWeight: "600",
                lineHeight: "1.2",
                fontSize: "1.5em",
                textAlign: "center!important",
              }}
            >
              General Donation to HI – Recurring, USA
            </h1>
            <p
              sx={{
                fontWeight: "400",
                lineHeight: "1.5",
                fontSize: "18px",
                textAlign: "justify",
              }}
            >
              Donations of any size make it possible for us to change lives by
              teaching Heartfulness techniques. Our dedicated volunteers, many
              of whom are professionals, donate their time and talents and cover
              their own expenses. Your donation will support our programs in
              schools, universities, colleges, corporations, hospitals, and
              communities worldwide.
              <p
                sx={{
                  fontWeight: "400",
                  lineHeight: "1.5",
                  fontSize: "18px",
                  textAlign: "justify",
                }}
              >
                All donations made are voluntary and are suggested to be made to
                the general fund of Heartfulness Institute. General funds are
                used for projects in areas of COVID 19 CARE, Environment, Animal
                shelter, Green initiatives, Positive lifestyle, and
                Sustainability initiatives, among others.
              </p>
            </p>
          </Box>
          <Box>
            <div
              sx={{
                margin: "auto",
                padding: "25px 15px",
                boxShadow: "0 2px 4px 0 rgba(170,170,170,0.5)",
                border: "solid 1px rgba(194,198,206,0.5)",
              }}
            >
              <p style={{ textAlign: "center" }}>
                For matching double donation from your organisation, please send
                email request to
                <a
                  sx={{
                    wordBreak: "break-word",
                    marginTop: "12px",
                    marginLeft: "5px",
                  }}
                  href="mailto:us.treasurer@heartfulnessinstitute.org?Subject=Matching Double Donation (https://donations.heartfulness.org/heartfulness-institute-donation-usa/)"
                  target="_top"
                  rel="noopener noreferrer"
                >
                  us.treasurer@heartfulnessinstitute.org
                </a>
              </p>
            </div>
          </Box>
          <Box>
            <div
              className="row"
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <div
                className="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center"
                style={{ marginTop: "15px" }}
              >
                <PageContent
                  eventCallback={eventCallback}
                  pageContent={pageContent(25, "Donate Now")}
                />
              </div>
              <div
                className="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                  padding: "8px",
                }}
              >
                <Box
                  sx={{
                    cursor: "pointer",
                    backgroundColor: "rgb(77, 143, 21)",
                    padding: "8px 25px",
                    borderRadius: "25px",
                    color: "white",
                    border: "0px",
                    width: "190px",
                    height: "38px",
                    // marginTop: "17px",
                  }}
                >
                  <Link
                    to="/recurring-general-donation-smsf-usa"
                    sx={{
                      color: "white",
                      ":hover": {
                        color: "white",
                        textDecoration: "none",
                      },
                    }}
                  >
                    Donate To SMSF
                  </Link>
                </Box>
              </div>
              <div
                className="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                  padding: "8px",
                }}
              >
                <Box
                  sx={{
                    cursor: "pointer",
                    backgroundColor: "rgb(77, 143, 21)",
                    padding: "8px 25px",
                    borderRadius: "25px",
                    color: "white",
                    border: "0px",
                    width: "190px",
                    height: "38px",
                  }}
                >
                  <Link
                    to="/recurring-general-donation-to-srcm-usa"
                    sx={{
                      color: "white",
                      ":hover": {
                        color: "white",
                        textDecoration: "none",
                      },
                    }}
                  >
                    Donate To SRCM
                  </Link>
                </Box>
              </div>
            </div>
          </Box>
        </Grid>
      </DonationLayout>
    </>
  );
};

export default RecurringGeneralDonationHI;
